@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer components {
  .button {
    @apply inline-block bg-orange-500 hover:bg-orange-600 font-bold rounded-xl px-4 py-2.5 text-white shadow-sm;
  }



}

header nav > ul {

}

header nav {
  > ul {
    @apply flex justify-center;

    > li {
      & + li {
        @apply ml-10;
      }

      & > a {
        @apply font-bold text-lg hover:text-orange-500;
      }

    }

  }



}